import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const GridE = () => {
  const data = useStaticQuery(graphql`
    {
      allMasonContentCreative(
        filter: { meta: { contentType: { eq: "product" } } }
        sort: { fields: updated_time, order: ASC}
      ) {
        nodes {
          url
          meta {
            position
            url
            order
          }
        }
        distinct(field: meta___order)
      }
    }
  `)
  return (
    <div className="grid-layout-wrap grid-e-wrap grid grid-cols-4 grid-rows-2 gap-4 my-4">
      {data.allMasonContentCreative.distinct.map(position => {
        let card = data.allMasonContentCreative.nodes.filter((elem)=>elem.meta.order===position)[0]
        return (
          <div className="grid-img-wrap small-grid-card">
            <a
              target="_blank"
              href={card.meta.url !== null ? card.meta.url : "/"}
            >
              <img
                className="w-full h-full object-cover"
                src={card.url}
                alt="product image"
              />
            </a>
          </div>
        )
      })}
    </div>
  )
}

export default GridE
