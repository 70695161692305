import * as React from "react"
import Banner from "../components/Banner/Banner"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
// import Seo from "../components/seo"

import GridA from "../components/GridA/GridA"
import GridB from "../components/GridB/GridB"
import GridC from "../components/GridC/GridC"
import SmallBanner from "../components/SmallBanner/SmallBanner"
import GridE from "../components/GridE/GridE"
import Seo from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title={"Garnet | Shoes"}/>
    <Banner />
    <GridA />
    <GridB />
    <GridC />
    <SmallBanner />
    <GridE />
  </Layout>
)


export default IndexPage
