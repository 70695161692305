import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const SmallBanner = () => {
  const data = useStaticQuery(graphql`
    {
      allMasonContentCreative(
        filter: { meta: { contentType: { eq: "banner" } } }
        sort: {fields: updated_time, order: ASC}
        limit: 1
      ) {
        nodes {
          url
          meta {
            position
            url
          }
        }
      }
    }
  `)
  return (
    <div className="banner-small-wrap mt-4 h-auto md:h-96 rounded-none md:rounded-md overflow-hidden">
      {data.allMasonContentCreative.nodes.map(card => (
        <div className="banner-img-wrap">
          <a target="_blank" href={card.meta.url !==null ? card.meta.url : "/"}>
            <img
              className="w-full h-full object-cover"
              src={card.url}
              alt="grid image"
            />
          </a>
        </div>
      ))}
    </div>
  )
}

export default SmallBanner
